export const Cities = [
  { country: "India" },
  { city: "Bangalore" },
  { city: "Mysuru" },
  { city: "Ahmedabad" },
  { city: "Chennai" },
  { city: "Delhi NCR" },
  { city: "Chandigarh Tricity" },
  { city: "Hyderabad" },
  { city: "Pune" },
  { city: "Ludhiana" },
  { city: "Mumbai" },
];
