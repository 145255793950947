// import logo from "./logo.svg";
import "./App.css";
// import List from "./Components/Service_pages/List";
import MainRoutes from "./Pages/MainRoutes";
import ServicePage from "./Pages/ServicePage";
// import Summary from "./Pages/Summary";
// import Split from "./Components/Split";

function App() {
  return (
    <div className="App">
      <MainRoutes />
      {/* <ServicePage/> */}
      {/* <List para="Shree"/> */}
      {/* <Summary/> */}
    </div>
  );
}

export default App;
