import { Box, Image } from "@chakra-ui/react";
import React from "react";
import mob from "../../assets/mobile.png";

const Body_3 = () => {
  return (
    <Box>
      <Image src={mob} />
    </Box>
  );
};

export default Body_3;
