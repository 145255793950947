import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD8ae4qjrbKJZJ07X5XyvWJgdzb3SD1568",
  authDomain: "servizio8901.firebaseapp.com",
  projectId: "servizio8901",
  storageBucket: "servizio8901.appspot.com",
  messagingSenderId: "109976090454",
  appId: "1:109976090454:web:6f8080649c588a943588f7",
  measurementId: "G-LQHQTW8S70",
};

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
