export const searchServices = [
  { service: "Salon for men" },
  { service: "Salon prime" },
  { service: "Salon for men royale" },
  { service: "Salon for women royale" },
  { service: "Salon for women" },
  { service: "Salon luxe" },
  { service: "Salon for kids" },
  { service: "Spa for men" },
  { service: "Spa for women" },
  { service: "Party makeup" },
  { service: "Therapies for men" },
  { service: "Therapies for women" },
  { service: "Home repair" },
  { service: "AC repair" },
  { service: "Electrician" },
  { service: "Appliances repair " },
  { service: "Home painting" },
  { service: "Home cleaning" },
  { service: "Bathroom and kitchen cleaning" },
  { service: "Sofa & Carpet cleaning" },
  { service: "Cockroach, ant & general pest control" },
  { service: "Termite control" },
  { service: "Massage therapies for men" },
  { service: "Massage therapies for women" },
  { service: "Carpenters" },
  { service: "Plumbers" },
  { service: "Furniture Making" },
];
