import { Box, Image } from "@chakra-ui/react";
import React from "react";
import uc from "../../assets/banner.png";

const Body_2 = () => {
  return (
    <Box>
      <Image src={uc} />
    </Box>
  );
};

export default Body_2;
