import React from 'react'

function List(prop) {
  return (
    <div>

        <li>{prop.para}</li>
      
    </div>
  )
}

export default List
